.signup-stepper li>div:first-of-type {
  flex-direction: column;
  align-items: flex-start;
}

.signup-stepper li>div span {
  color: white;
  opacity: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin: 0;
}

.signup-stepper li>div>div:first-of-type {
  background: #3B5B89;
  border: none;
}

.signup-stepper li>div>div:first-of-type[data-highlighted] {
  background: #009EE1;
}

.signup-stepper li>div>div:first-of-type[data-highlighted] svg {
  color: #ffffff;
}

.signup-stepper li>div>div:first-of-type svg {
  color: #9DADC4;
  width: 20px !important;
  height: 20px !important;
}

.signup-stepper li>div>div:nth-of-type(2) {
  margin-top: 32px;
  max-width: 100px;
}

.signup-stepper li>div:last-of-type {
  display: none;
}

.signup-stepper.chakra-steps {
  position: relative;
  align-items: flex-start;
}

.signup-stepper li>div>div:first-of-type:before {
  content: "";
  position: absolute;
  left: 40px;
  height: 1px;
  background: #223551;
  display: block;
  top: 20px;
  width: 100%;
}

.signup-stepper li>div>div:first-of-type[data-highlighted]:before {
  background: #009EE1;
}

.signup-stepper li:last-of-type>div>div:first-of-type:before {
  display: none;
}

.active {
  position: relative;
}

.active:before {
  background: #4C505F;
  content: "";
  position: absolute;
  border-radius: 8px;
  width: 100%;
  border-bottom: 1px solid #009EE1;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.active>div {
  color: white;
}

.dropdown-btn span {
  display: inline-flex;
  align-items: center;
}

.tab-with-icons button[aria-selected=true] {
  position: relative;
}

.tab-with-icons>.chakra-tabs__tablist button:focus {
  box-shadow: none;
  background: transparent;
}

.tab-with-icons>.chakra-tabs__tablist button[aria-selected=true]:after {
  content: "";
  position: absolute;
  background: #F89B36;
  height: 3px;
  left: 7px;
  right: 7px;
  border-radius: 10px 10px 0 0;
  bottom: 1px;
}

.richText-editor .ql-container {
  border: none !important;

  .ql-editor {
    min-height: 172px;
  }
}

.richText-editor {
  width: 100%;
  border: 1px solid #C5C7D0;
  border-radius: 10px;
  overflow: hidden;
}

.richText-editor .ql-toolbar.ql-snow {
  text-align: left;
  border: none;
  background-color: #DBDBDB;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol_Sortable div {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #444444;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol_Sortable span {
  color: #2A2A2A;
  font-size: 10px;
}

.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2A2A2A;
}

.rdt_Table .rdt_TableBody .rdt_TableRow {
  border: none;
  min-height: 65px;
  cursor: pointer;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background: #EEEEEE !important;
}

.rdt_Table .rdt_TableHeadRow {
  border: none;
  min-height: 65px;
  border-bottom: 1px solid #f1f1f1;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:nth-of-type(odd) {
  background: #f9f9f9;
}

.rdt_Table {
  border-radius: 12px;
}

.rdt_Pagination span,
.rdt_Pagination select,
.rdt_Pagination button {
  font-size: 16px;
  color: #2A2A2A;
}

.rdt_Pagination {
  min-height: 60px !important;
}

.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell,
.rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  padding: 0 14px;
}

.redux-toastr .toastr {
  display: flex;
  align-items: center;
  min-height: 62px !important;
  margin: 20px 0;
  opacity: 1 !important;
  box-shadow: none !important;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto !important;
  padding: 0 20px;
  top: 0px !important;
  bottom: 0 !important;
  margin: 0 !important;
  height: auto !important;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder .toastr-icon {
  width: 26px !important;
  margin: 0;
}

.redux-toastr .toastr .rrt-middle-container {
  width: calc(90% - 80px) !important;
  line-height: 19px;
  margin-left: 64px !important;
}

.redux-toastr .toastr .rrt-middle-container>div {
  width: 100%;
}

.redux-toastr .toastr .close-toastr {
  color: white !important;
  opacity: 1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 0 25px;
}

.redux-toastr .top-center {
  padding: 0 20px !important;
  width: auto !important;
  min-width: 380px;
}

.redux-toastr .toastr>div {
  width: 100%;
}

.redux-toastr .toastr.rrt-error .rrt-left-container .rrt-holder .toastr-icon {
  width: 29px !important;
  margin-top: 6px !important;
}

.img-cropper {
  max-height: 250px;
}

.img-preview {
  height: 150px !important;
  width: 150px !important;
  overflow: hidden;
}

.addReport-stepper .css-zdz6fk[aria-current=step] {
  background: #F89D2C;
  border: none;
}

.addReport-stepper li>div:first-of-type>div:last-of-type[aria-current=step] {
  background: #F89D2C;

  span {
    color: #fff;
  }
}


.addReport-stepper .css-zdz6fk[aria-current=step] svg {
  fill: white;
}

.addReport-stepper .css-1qozmav {
  border: none;
  position: relative;
  margin: 0 0 0 20px;
}

.addReport-stepper .css-1qozmav:before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  bottom: 0;
  width: 1px;
  background: #D9F4FF;
}

.addReport-stepper li>div:first-of-type>div:last-of-type {
  margin-left: 20px;
  background: rgba(34, 61, 120, 0.2);
  border-radius: 4px;
  height: 34px;
  padding: 0 10px;
}

.addReport-stepper li>div:first-of-type>div:last-of-type span {
  font-size: 21px;
  line-height: 26px;
  color: #2A2A2A;
}

.addReport-stepper .chakra-collapse {
  overflow: visible !important;
}

.dateTime-picker .react-datetime-picker__inputGroup__input {
  height: 46px;
}

.dateTime-picker {
  width: 65%;
  z-index: 2;
}

.schedule-inspection-to {
  z-index: 0;
}

.schedule-inspection-date {
  .react-datetime-picker__calendar.react-datetime-picker__calendar--open {
    left: -0px !important;
  }
}

.dateTime-picker .react-datetime-picker__wrapper {
  border: 1px solid #C5C7D0;
  padding: 0 15px;
  border-radius: 4px;
  color: #2A2A2A;
  font-size: 18px;
}

.dateTime-picker .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.dateTime-picker .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #223D78;
}

.dateTime-picker .react-datetime-picker__wrapper input:focus {
  outline: none;
}

.dateTime-picker .react-datetime-picker__wrapper input::placeholder {
  color: #C5C7D0;
}

.dateTime-picker .react-datetime-picker__inputGroup {
  display: inline-flex;
  align-items: center;
}

.addReport-stepper .css-zdz6fk[data-highlighted] {
  background: #009EE1;
  border-color: #009EE1;
  color: white;
}

.addReport-stepper .css-zdz6fk svg {
  width: 18px;
  color: #888888;
  stroke-width: 2px;
}

.addReport-stepper .css-zdz6fk {
  width: 36px;
  height: 36px;
}

.addReport-stepper .client-info-step svg {
  width: 24px !important;
  height: auto !important;
}

.addReport-stepper .css-zdz6fk[data-highlighted] svg {
  color: white;
  width: 18px !important;
}

.addReport-stepper .property-details .css-k008qs svg {
  width: 20px;
  stroke-width: 1px;
  stroke: #888888;
}

.addReport-stepper .property-details .css-zdz6fk[data-highlighted] svg {
  stroke: transparent;
}

.addReport-stepper .property-details .css-zdz6fk[aria-current=step] svg {
  stroke: white;
}

.addReport-stepper .weather-conditions .css-k008qs svg {
  stroke-width: 0 !important;
  width: 22px !important;
  height: unset !important;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 42px;
  box-shadow: 0px 0px 20px rgba(127, 206, 240, 0.3);
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 42px;
  width: 70px;
  left: 0;
  top: 0;
  -webkit-transition: .4s;
  transition: .2s;
  border-radius: 30px;
  background: #223D78;
}

input:checked+.slider:before {
  -webkit-transform: translateX(70px);
  -ms-transform: translateX(70px);
  transform: translateX(70px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-switch .celcius {
  font-size: 21px;
  color: white;
  position: absolute;
  left: 18px;
  top: 5px;
  display: inline-block;
}

.toggle-switch .fahrenheit {
  font-size: 21px;
  color: white;
  position: absolute;
  right: 11px;
  top: 5px;
  display: none;
}

.toggle-switch input:checked~.slider+.celcius+.fahrenheit {
  display: inline-block;
}

.toggle-switch input:checked~.slider+.celcius {
  display: none;
}

.toggle-switch input:checked .fahrenheit {
  display: inline-block;
}

.toggle-switch .slider:after {
  position: absolute;
  content: "F";
  right: 29px;
  top: 7px;
  color: #888888;
  font-size: 18px;
}

.slider:after {
  position: absolute;
  content: "F";
  right: 29px;
  top: 7px;
  color: #888888;
  font-size: 18px;
}

.toggle-switch input:checked~.slider:after {
  left: 29px;
  right: unset;
  content: "C";
}

.inspection-stepper .css-zdz6fk svg {
  stroke-width: 1px;
  stroke: #888888;
}

.inspection-stepper .css-zdz6fk[aria-current=step] svg {
  stroke-width: 1px;
  stroke: white;
}

.rmsc.multiselect-dropdown {
  width: 100%;
}

.rmsc.multiselect-dropdown .dropdown-heading-value {
  text-align: left;
  font-size: 18px;
}

.rmsc.multiselect-dropdown .dropdown-heading {
  height: 48px;
  color: #c5c7d0;
  padding: 0 16px;
  cursor: pointer;
}

.rmsc.multiselect-dropdown .dropdown-container {
  border-color: #c5c7d0;
  background: transparent;
}

.rmsc.multiselect-dropdown .dropdown-container:focus-within {
  box-shadow: none;
  border-color: #c5c7d0;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  font-size: 14px !important;
  box-shadow: 0px 0px 12px #eeeeee;
}

.rdrCalendarWrapper .rdrDay {
  height: 2.8em;
}

.rdrCalendarWrapper .rdrMonthName {
  padding: 0px 13px 8px;
  font-size: 15px;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper {
  padding: 0;
  height: 50px;
}

.rdrCalendarWrapper .rdrMonthAndYearPickers select {
  font-size: 15px;
}

button:hover[disabled] {
  background: #4dbfea !important;
}

.range-picker {
  width: 100%;
}

.range-picker input.react-daterange-picker__inputGroup__input,
.range-picker .react-daterange-picker__inputGroup__leadingZero {
  height: 48px;
  font-size: 18px;
  line-height: 48px;
}

.range-picker input.react-daterange-picker__inputGroup__input:focus {
  outline: none;
}

.range-picker .react-daterange-picker__wrapper {
  border-radius: 4px;
  border-color: #c5c7d0;
  padding: 0 16px;
}

.range-picker .react-daterange-picker__inputGroup {
  display: inline-flex;
  align-items: center;
  min-width: unset;
  flex-grow: unset;
}

.range-picker .react-daterange-picker__calendar-button {
  margin-right: 6px;
}

.range-picker .react-daterange-picker__clear-button.react-daterange-picker__button {
  margin-left: auto;
}

.dateTime-picker .react-datetime-picker__button {
  display: none;
}

.range-picker .react-daterange-picker__button {
  display: none;
}

.addReport-stepper>li {
  margin-bottom: 10px;
}

.chakra-modal__content {
  width: 95% !important;
  max-height: 86vh !important;
  margin: 40px 0 !important;
}

.radio-black-label~.chakra-radio__label {
  color: #2a2a2a;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tui-image-editor-container.top .tui-image-editor-controls .tui-image-editor-controls-logo {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls-buttons {
  padding: 4px;
}

.country-dropdown select {
  height: 52px;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 20px;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
}

.country-dropdown select:focus {
  outline: none;
}

.country-dropdown {
  position: relative;
}

.country-dropdown:after {
  content: "";
  border: solid #676879;
  display: inline-block;
  border-width: 0 2px 2px 0;
  position: absolute;
  right: 15px;
  top: 20px;
  transform: rotate(45deg);
  width: 7px;
  height: 7px;
}

.inspection-datePicker .react-datetime-picker__calendar {
  right: 0 !important;
  left: unset !important;
}

.w-100 {
  width: 100% !important;
}

.z-index-unset {
  z-index: unset !important;
}

.statistics-line-chart {
  height: 100px;
}

.revenue-line-chart {
  height: 240px;
}

.rbc-calendar .rbc-header {
  color: #888888;
  padding: 10px;
}

.rbc-calendar .rbc-event {
  background: #d8e3f9;
  color: #2B2B30;
  border-radius: 0;
  border-left: 3px solid #223D78;
  padding: 4px 10px;
}

.rdt_Pagination .sc-bZkfAO {
  margin: 0 6px;
}

.rdt_Pagination button {
  padding: 0;
  width: 30px;
}

.rdt_Pagination button:hover[disabled] {
  background: transparent !important;
}

.rdt_Pagination button:focus {
  background: transparent;
}

.thumb-vertical,
.thumb-horizontal {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.tui-image-editor-container .tui-image-editor-controls-buttons button.tui-image-editor-download-btn {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls .tui-image-editor-controls-buttons {
  width: auto;
  margin: 8px 30px;
}

.carousel.carousel-slider .slide img {
  width: auto;
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel.carousel-slider .control-dots .dot {
  background: #727272;
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.carousel.carousel-slider .control-dots {
  margin: 0 !important;
}

#tool {
  top: 0px;
}

.annotate-container {
  position: relative;
  border: none !important;
}

.annotate-container>[id^=baseLayer] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: grey;
}

.annotate-container>[id^=drawingLayer] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

.annotator-options {
  z-index: 9999;
  max-width: 1152px;
  width: 100%;
  text-align: center;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  font-size: 17px;
  justify-content: center;
  top: 100px !important;
}

.annotator-options input {
  margin: 0px 5px 0px 10px;
}

div#canvas-annotate {
  overflow: auto;
}

.annotator-options input[data-tool="text"] {
  display: none;
}

.annotator-options #redoaction {
  margin-left: 10px;
}

.annotator-options button {
  background: #e7e7e7;
  padding: 1px 10px;
  border-radius: 4px;
}

.filter-dateTime-picker {
  width: 100%;
}

.drag-drop input {
  width: 0;
  height: 0;
}

.weather-text {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid #111429;
    border-radius: 50%;
    left: 29px;
    top: 5px;
  }
}

.black-square {
  background-color: #000;
  height: 21px;
  width: 21px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
}

.green-square {
  background-color: #00CA72;
  height: 21px;
  width: 21px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
}

.blue-square {
  background-color: #009EE1;
  height: 21px;
  width: 21px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
}

.orange-square {
  background-color: #D64000;
  height: 21px;
  width: 21px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
}

.template-dropdown-text {
  color: #2a2a2a;
}

.template-tab-border {
  color: gray;

  &.border-active {
    position: relative;
    color: #F89B36;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: #F89B36;
      border-radius: 10px 10px 0px 0px;
    }
  }
}

.illustrative-photos-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.template-name {
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inspector-email {
  width: 310px;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inspector-name {
  width: 220px;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-job-to-calender {
  z-index: 0;
}

.add-job-date-calender {
  .react-datetime-picker__calendar {
    left: 0 !important;
  }
}

.google-map-input {
  height: 40px;
  border-radius: 50px;
  top: 20px !important;
  width: 90%;
  left: 50% !important;
  transform: translate(-50%, 0);
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}

.customize-scrollbar {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
    border-radius: 10px;
    background: #E0E0E0;
  }

  &::-webkit-scrollbar-thumb {
    background: #C5C7D0;
    border-radius: 10px
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
}

.table-data-no-border {
  border: none !important;
  text-align: right !important;
}

.date-picker .react-datetime-picker__inputGroup__month {
  display: none;
}

.date-picker .react-datetime-picker__inputGroup__divider {
  display: none;
}

.date-picker .react-datetime-picker__inputGroup__day {
  display: none;
}

.date-picker .react-datetime-picker__inputGroup__year {
  display: none;
}

.date-picker .react-datetime-picker__inputGroup__hour~.react-datetime-picker__inputGroup__divider {
  display: inline-block;
}

input:disabled::selection {
  background: transparent !important;
}

.date-picker input[name='inspectionEndDate']+.react-datetime-picker__inputGroup__leadingZero,
.date-picker input[name='inspectionFrom']+.react-datetime-picker__inputGroup__leadingZero,
.date-picker input[name='inspectionTo']+.react-datetime-picker__inputGroup__leadingZero,
.date-picker input[name='inspectionEndDate']~.react-datetime-picker__inputGroup__leadingZero,
.date-picker input[name='inspectionTo']~.react-datetime-picker__inputGroup__leadingZero,
.date-picker input[name='inspectionFrom']~.react-datetime-picker__inputGroup__leadingZero {
  display: none;
}

.date-picker .react-datetime-picker__inputGroup__hour~.react-datetime-picker__inputGroup__divider~span.react-datetime-picker__inputGroup__leadingZero {
  display: inline-block !important;
}

.inspection-checkbox[data-disabled] {
  cursor: pointer;
}

.inspection-checkbox .chakra-checkbox__control[data-checked][data-disabled] {
  background: transparent;
  border-color: #FCE6BC;
}

.inspection-checkbox .chakra-checkbox__label[data-disabled] {
  opacity: 1;
}

input.pac-target-input {
  display: inline-block !important;
}

.datepicker-ui .react-datetime-picker__inputGroup__divider {
  position: relative;
  color: #f6f6f6;
  padding: 0 2px;
}

.datepicker-ui .react-datetime-picker__inputGroup__divider:before {
  position: absolute;
  left: 1px;
  content: "/";
  color: black;
  top: -1px;
}

.switch .chakra-checkbox__control {
  padding: 2px;
  width: 42px;
  height: 22px;
  border-radius: 30px;
  background: #c4c4c4;
  border: none;
  display: inline-block;
  position: relative;
}

.switch .chakra-checkbox__control>div {
  display: none !important;
}

.switch .chakra-checkbox__control[data-checked] {
  background: #ff928e;
}

.switch .chakra-checkbox__control:before {
  background: white;
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  display: inline-block;
  content: "";
  transition: 0.3s all ease;
  position: absolute;
  left: 2px;
}

.switch .chakra-checkbox__control[data-checked]:before {
  left: 22px;
}

.user-guide-video {
  width: 100%;
  height: 560px;
}

.chakra-pin-input {
  height: 48px !important;
  width: 48px !important;
  font-size: 18px !important;
  min-width: 48px !important;
}

.preview-template .chakra-modal__content {
  background: #f6f6f6;
}

.chakra-checkbox__control[data-disabled] {
  background: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
}

.pac-container {
  z-index: 9999;
}

/* RESPONSIVE STYLING */

@media screen and (max-width: 1549px) {
  .addReport-stepper li>div:first-of-type>div:last-of-type {
    margin-left: 10px;
  }

  .addReport-stepper li>div:first-of-type>div:last-of-type span {
    font-size: 18px;
  }

  .toggle-switch .celcius {
    font-size: 18px;
    top: 7px;
  }

  .toggle-switch .fahrenheit {
    font-size: 18px;
    top: 7px;
  }

  .dateTime-picker {
    width: 100%;
  }
}

@media screen and (max-width: 1379px) {
  .statistics-line-chart {
    height: 100px;
  }

  .revenue-line-chart {
    height: 280px;
  }
}

@media screen and (max-width: 991px) {
  .chakra-tabs__tablist button {
    font-size: 16px;
  }

  .chakra-pin-input {
    height: 44px !important;
    width: 44px !important;
    min-width: 44px !important;
  }
}